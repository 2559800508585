import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import moment from 'moment';
import queryString from 'qs';
import { Datatable } from '../../../core/components/datatable/datatable.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DefaultService<T> {
  protected url = '';
  protected readonly http = inject(HttpClient);

  getList(params?: any) {
    params = this.setFilterParams(params);
    return this.http.get<T[]>(`${this.url}?${queryString.stringify(params, { encode: false })}`);
  }

  getDatatableData(params?: any): Observable<Datatable<T>> {
    return this.http.get<Datatable<T>>(`${this.url}?${queryString.stringify(params, { encode: false })}`);
  }

  findData(id: number) {
    return this.http.get<T>(`${this.url}/${id}`);
  }

  create(data: T) {
    return this.http.post<T>(this.url, data);
  }

  deleteById(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateById(id: number, data: Partial<T>) {
    return this.http.put<T>(`${this.url}/${id}`, data);
  }

  protected setFilterParams(params: any) {
    if (!params?.filters) return params;
    const result: any = {};
    for (const [key, value] of Object.entries(params.filters)) {
      if (value !== undefined && value !== null && value !== '') {
        result[key] = value;
      }
    }
    return { filters: result };
  }

  protected downLoadFileExcel(p: { data: ArrayBuffer; filename: string }) {
    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob = new Blob([p.data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = this.getFilename(p.filename);
    anchor.href = url;
    anchor.click();
  }

  private getFilename(filename: string) {
    const mDate = moment();
    const date = mDate.format('YYYY-MM-DD');
    const time = mDate.format('HH.mm');
    return `${filename} ${date} at ${time}.xlsx`;
  }
}
